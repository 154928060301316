import React, {useRef, useState} from 'react';
import {auth} from '../../../firebase-config';
import {createUserWithEmailAndPassword, sendEmailVerification, signOut} from 'firebase/auth';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
import ReCAPTCHA from 'react-google-recaptcha';
import ReusableModal from '../../ReusableModal';
import 'leaflet/dist/leaflet.css';
import {getFunctions, httpsCallable} from "firebase/functions";
import PhoneInput from "react-phone-input-2";

// Helper component to handle map interaction
const SetViewOnClick = ({coords}) => {
    const map = useMap();
    map.setView(coords, 15);
    return null;
};

const PatientRegistrationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        website: '',
        contactPhone: '',
        contactName: '',
        address: '',
        location: {lat: 0, lng: 0}
    });
    const [collaboration, setCollaboration] = useState('no');
    const [collaborationDetails, setCollaborationDetails] = useState('');
    const [poConnection, setPoConnection] = useState('no');
    const [poConnectionDetails, setPoConnectionDetails] = useState('');
    const [briefDescription, setBriefDescription] = useState("");
    const [europeanReferenceNetwork, setEuropeanReferenceNetwork] = useState("no");
    const [europeanReferenceNetworkDetails, setEuropeanReferenceNetworkDetails] = useState("");
    const [numberOfPatients, setNumberOfPatients] = useState(1);
    const [suggestions, setSuggestions] = useState([]);
    const [accredited, setAccredited] = useState("no");
    const [accreditationYear, setAccreditationYear] = useState("");
    const [accreditedForChildren, setAccreditedForChildren] = useState(false);
    const [accreditedForAdults, setAccreditedForAdults] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const mapRef = useRef();

    // Handle reCAPTCHA change
    const onRecaptchaChange = (value) => {
        setRecaptchaValue(value); // Store the reCAPTCHA value
    };

    const handleAccreditedChange = (e) => {
        setAccredited(e.target.value);
        if (e.target.value === "no") {
            // Reset additional fields if "no" is selected
            setAccreditationYear("");
            setAccreditedForChildren(false);
            setAccreditedForAdults(false);
        }
    };

    const handleAccreditationYearChange = (e) => {
        setAccreditationYear(e.target.value);
    };

    const handleAccreditedForChildrenChange = (e) => {
        setAccreditedForChildren(e.target.checked);
    };

    const handleAccreditedForAdultsChange = (e) => {
        setAccreditedForAdults(e.target.checked);
    };

    const handleBriefDescriptionChange = (e) => {
        if (e.target.value.length <= 500) {
            setBriefDescription(e.target.value);
        }
    };

    const handleEuropeanReferenceNetworkChange = (e) => {
        setEuropeanReferenceNetwork(e.target.value);
        // Reset details if "No" is selected
        if (e.target.value === 'no') {
            setEuropeanReferenceNetworkDetails("");
        }
    };

    const handleEuropeanReferenceNetworkDetailsChange = (e) => {
        if (e.target.value.length <= 200) {
            setEuropeanReferenceNetworkDetails(e.target.value);
        }
    };

    const handleNumberOfPatientsChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0) {
            setNumberOfPatients(value);
        } else {
            // Optionally, clear or ignore if the value is invalid
            setNumberOfPatients(0);
        }
    };

    const handleCollaborationChange = (e) => {
        setCollaboration(e.target.value);
        if (e.target.value === 'no') {
            setCollaborationDetails(''); // Clear details if "No" is selected
        }
    };

    const handlePoConnectionChange = (e) => {
        setPoConnection(e.target.value);
        if (e.target.value === 'no') {
            setPoConnectionDetails(''); // Clear details if "No" is selected
        }
    };

    const handleCollaborationDetailsChange = (e) => {
        if (e.target.value.length <= 300) {
            setCollaborationDetails(e.target.value);
        }
    };

    const handlePoConnectionDetailsChange = (e) => {
        if (e.target.value.length <= 300) {
            setPoConnectionDetails(e.target.value);
        }
    };

    // Handle Nominatim search
    const handleAddressChange = async (e) => {
        const address = e.target.value;
        setFormData(prevState => ({...prevState, address}));

        if (address.length > 2) { // Start searching after typing 3 characters
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching address suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    // Select an address from suggestions
    const handleSelectAddress = (place) => {
        const location = {
            lat: parseFloat(place.lat), lng: parseFloat(place.lon)
        };
        setFormData(prevState => ({
            ...prevState, address: place.display_name, location
        }));
        setSuggestions([]);

        // Move the map to the selected location
        if (mapRef.current) {
            mapRef.current.setView(location, 15); // Adjust zoom level as needed
        }
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaValue) { // Check if reCAPTCHA is completed
            console.error('Please complete the reCAPTCHA');
            setModalMessage("Please complete the reCAPTCHA");
            setIsModalOpen(true);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            // Handle password mismatch
            console.error('Passwords do not match');
            setModalMessage("Passwords do not match");
            setIsModalOpen(true);
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            await sendEmailVerification(user);
            setModalMessage("Thank you for signing up! To complete your registration and verify your account, please check your email inbox for a verification message we’ve just sent to you.");
            setIsModalOpen(true);

            await signOut(auth);

            const functions = getFunctions();
            const registerUser = httpsCallable(functions, 'registerUser');

            const result = await registerUser({
                uid: user.uid,
                userType: 'care center',
                name: formData.name,
                email: formData.email,
                website: formData.website,
                address: formData.address,
                contactName: formData.contactName,
                contactPhone: formData.contactPhone,
                location: formData.location,
                collaboration,
                collaborationDetails,
                poConnection,
                poConnectionDetails,
                numberOfPatients,
                briefDescription,
                europeanReferenceNetwork,
                europeanReferenceNetworkDetails,
                accredited,
                accreditationYear,
                accreditedForChildren,
                accreditedForAdults
            });

            console.log('User registered successfully:', result.data.message);
        } catch (error) {
            console.error('Error registering user:', error.message);
        }
    };

    return (<div>
        <div className="registration-header">
            <h1 className="registration-title">Care Center Registration</h1>
            <p className="registration-subtitle">
                With you joining this collaboration, we’re one step closer to connect the global Duchenne community,
                regardless of fields and borders. Please fill in the form below to access Duchenne Map and participate
                in
                creating a global network of specialised DMD care.
            </p>
        </div>
        <div className="registration-form-container">
            <form onSubmit={handleSubmit} className="registration-form">
                <div className="form-group">
                    <label htmlFor="name">Care Center Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Care Center"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="john.doe@example.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input
                        type="url"
                        id="website"
                        name="website"
                        placeholder="https://example.com"
                        value={formData.website}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Contact Person Name</label>
                    <input
                        type="text"
                        id="contactName"
                        placeholder="John Doe"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Contact Person Phone</label>
                    <PhoneInput
                        country={'gr'}
                        value={formData.contactPhone}
                        onChange={contactPhone => setFormData(prevState => ({...prevState, contactPhone}))}
                        inputProps={{
                            name: 'contactPhone', required: true
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="numberOfPatients">Number of Patients</label>
                    <input
                        type="number"
                        id="numberOfPatients"
                        name="numberOfPatients"
                        placeholder="Enter the number of patients"
                        value={numberOfPatients}
                        onChange={handleNumberOfPatientsChange}
                        min="1"
                    />
                </div>
                <div className="collaboration-section">
                    <div className="form-group">
                        <label htmlFor="briefDescription">Brief Description</label>
                        <textarea
                            id="briefDescription"
                            name="briefDescription"
                            placeholder="Please provide a brief description of your care center"
                            value={briefDescription}
                            onChange={handleBriefDescriptionChange}
                            maxLength={1000}
                            rows={4}
                        />
                    </div>
                    <label>Is the care center accredited?</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="accredited"
                                value="yes"
                                checked={accredited === "yes"}
                                onChange={handleAccreditedChange}
                            />
                            Yes
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="accredited"
                                value="no"
                                checked={accredited === "no"}
                                onChange={handleAccreditedChange}
                            />
                            No
                        </label>
                    </div>
                    {accredited === "yes" && (
                        <>
                            <div className="form-group">
                                <label htmlFor="accreditationYear">Accreditation Year</label>
                                <input
                                    type="number"
                                    id="accreditationYear"
                                    name="accreditationYear"
                                    placeholder="Enter the year of accreditation"
                                    value={accreditationYear}
                                    onChange={handleAccreditationYearChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>The center is accredited for:</label>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="accreditedForChildren"
                                            checked={accreditedForChildren}
                                            onChange={handleAccreditedForChildrenChange}
                                        />
                                        Children
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="accreditedForAdults"
                                            checked={accreditedForAdults}
                                            onChange={handleAccreditedForAdultsChange}
                                        />
                                        Adults
                                    </label>
                                </div>
                            </div>
                        </>
                    )}
                    <label>Is your care center part of any European Reference Networks?</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="europeanReferenceNetwork"
                                value="yes"
                                checked={europeanReferenceNetwork === 'yes'}
                                onChange={handleEuropeanReferenceNetworkChange}
                            />
                            Yes
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="europeanReferenceNetwork"
                                value="no"
                                checked={europeanReferenceNetwork === 'no'}
                                onChange={handleEuropeanReferenceNetworkChange}
                            />
                            No
                        </label>
                    </div>
                    {europeanReferenceNetwork === 'yes' && (<div className="form-group">
                        <label htmlFor="europeanReferenceNetworkDetails">European Reference Networks Details</label>
                        <textarea
                            id="europeanReferenceNetworkDetails"
                            name="europeanReferenceNetworkDetails"
                            placeholder="Provide details about your involvement"
                            value={europeanReferenceNetworkDetails}
                            onChange={handleEuropeanReferenceNetworkDetailsChange}
                            maxLength={200}
                            rows={3}
                        />
                        <small>{200 - europeanReferenceNetworkDetails.length} characters remaining</small>
                    </div>)}
                    <label>Do you collaborate with other centers or hospitals?</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="collaboration"
                                value="yes"
                                checked={collaboration === 'yes'}
                                onChange={handleCollaborationChange}
                            />
                            Yes
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="collaboration"
                                value="no"
                                checked={collaboration === 'no'}
                                onChange={handleCollaborationChange}
                            />
                            No
                        </label>
                    </div>
                    {collaboration === 'yes' && (<div className="form-group">
                        <label htmlFor="collaborationDetails">Collaboration Details</label>
                        <textarea
                            id="collaborationDetails"
                            name="collaborationDetails"
                            placeholder="Please provide the name of the center/hospital and the nature of your collaboration"
                            value={collaborationDetails}
                            onChange={handleCollaborationDetailsChange}
                            maxLength={300}
                            rows={4}
                        />
                        <small>{300 - collaborationDetails.length} characters remaining</small>
                    </div>)}
                    <label>Are you connected with or supported by any patient organization?</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="poConnection"
                                value="yes"
                                checked={poConnection === 'yes'}
                                onChange={handlePoConnectionChange}
                            />
                            Yes
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="poConnection"
                                value="no"
                                checked={poConnection === 'no'}
                                onChange={handlePoConnectionChange}
                            />
                            No
                        </label>
                    </div>
                    {poConnection === 'yes' && (<div className="form-group">
                        <label htmlFor="poConnectionDetails">Patient Organization Details</label>
                        <textarea
                            id="poConnectionDetails"
                            name="poConnectionDetails"
                            placeholder="Please provide the name of the patient organization"
                            value={poConnectionDetails}
                            onChange={handlePoConnectionDetailsChange}
                            maxLength={50}
                            rows={2}
                        />
                        <small>{50 - poConnectionDetails.length} characters remaining</small>
                    </div>)}
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleAddressChange}
                        placeholder="Enter your address"
                        required
                    />
                    {suggestions.length > 0 && (<ul className="suggestions-dropdown">
                        {suggestions.map((suggestion, index) => (
                            <li key={index} onClick={() => handleSelectAddress(suggestion)}>
                                {suggestion.display_name}
                            </li>))}
                    </ul>)}
                </div>
                <div className="map-container">
                    <MapContainer
                        center={formData.location}
                        zoom={formData.location.lat !== 52.26191870193713 || formData.location.lng !== 5.485825844485596 ? 15 : 5} // Default zoom level
                        style={{height: '200px', width: '100%'}}
                        whenCreated={mapInstance => mapRef.current = mapInstance}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={formData.location}>
                            <Popup>{formData.address || 'Default Location'}</Popup>
                        </Marker>
                        <SetViewOnClick coords={formData.location}/>
                    </MapContainer>
                </div>
                <div className="form-group">
                    <ReCAPTCHA
                        sitekey="6Ld4UUkqAAAAAPtC-sfrkW1ZURPBTsxJ-PhpicQr"
                        onChange={onRecaptchaChange}
                    />
                </div>
                <button type="submit" className="register-button">Register</button>
                <div className="privacy-text">
                    By signing up, I agree to the <a href="/documents/Privacy%20Policy.pdf" target="_blank"
                                                     rel="noopener noreferrer">Duchenne Map Privacy Policy</a> and <a
                    href="/documents/Terms%20and%20Conditions.pdf" target="_blank"
                    rel="noopener noreferrer">Terms and Conditions</a>
                </div>
            </form>
            <ReusableModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Notification"
                message={modalMessage}
                buttonText="Close"
            />
        </div>
    </div>);

};

export default PatientRegistrationForm;
