import React, {useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {db} from '../../firebase-config';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import Select from 'react-select';
import {Box, Button, CircularProgress, Container, IconButton, Paper, TextField, Typography,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Social media platforms
const socialMediaOptions = [{value: 'facebook', label: 'Facebook'}, {
    value: 'twitter', label: 'Twitter'
}, {value: 'linkedin', label: 'LinkedIn'}, {value: 'instagram', label: 'Instagram'},];

// Predefined list of services
const servicesOptions = [{value: 'genetic_counseling', label: 'Genetic counseling'}, {
    value: 'occupational_therapy', label: 'Occupational therapy (OT)'
}, {value: 'orthopedics', label: 'Orthopedics'}, {value: 'orthotics', label: 'Orthotics'}, {
    value: 'palliative_care', label: 'Palliative care'
}, {value: 'physical_therapy', label: 'Physical therapy'}, {
    value: 'psychology', label: 'Psychology'
}, {value: 'social_work', label: 'Social work'}, {
    value: 'wheelchair_specialist', label: 'Wheelchair specialist'
}, {value: 'counseling', label: 'Counseling'}, {value: 'nutrition', label: 'Nutrition'}, {
    value: 'speech_therapy', label: 'Speech therapy'
}, {value: 'clinic_coordination', label: 'Clinic coordination'}, {
    value: 'dme', label: 'Durable medical equipment (DME)'
},];

// Predefined roles for team members
const teamMemberRoles = [{value: '0', label: 'Chair'}, {value: '1', label: 'Board member'}, {
    value: '2', label: 'Administration'
}, {value: '3', label: 'Policy'}, {value: '4', label: 'Advocacy / Awareness'}, {
    value: '5', label: 'Research Support'
}, {value: '6', label: 'Clinical Trials Support'}, {value: '7', label: 'Community Management'}, {
    value: '8', label: 'Community Support'
}, {value: '9', label: 'Communications'}, {value: '10', label: 'Fundraising'}, {
    value: '11', label: 'Project Management'
}, {value: '12', label: 'Staff'}, {value: '13', label: 'Finance'},];

const ProfilePage = () => {
    const [userData, setUserData] = useState(null); // Track user data
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [isEditing, setIsEditing] = useState(false); // Track edit mode
    const [formData, setFormData] = useState({}); // Track form data
    const [selectedServices, setSelectedServices] = useState([]); // Track selected services
    const [socialMediaLinks, setSocialMediaLinks] = useState([{platform: '', url: ''}]); // Track social media links
    const [teamMembers, setTeamMembers] = useState([]); // Track team members
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async (user) => {
            const docRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setUserData(data);
                setFormData(data); // Set form data with user data
                if (data.services) {
                    setSelectedServices(data.services.map(service => ({
                        value: service, label: service
                    }))); // Set initial services if they exist
                }
                if (data.socialMediaLinks) {
                    setSocialMediaLinks(data.socialMediaLinks); // Set initial social media links if they exist
                }
                if (data.teamMembers) {
                    setTeamMembers(data.teamMembers); // Set initial team members if they exist
                }
            } else {
                console.log('No such document!');
            }
            setIsLoading(false); // Data loaded, stop loading state
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user); // Fetch user data if the user is authenticated
            } else {
                setIsLoading(false); // No user, stop loading state
                setUserData(null); // Reset user data
            }
        });

        return () => unsubscribe(); // Clean up listener on unmount
    }, [auth]);

    // Handle input changes
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    // Handle service selection changes
    const handleServiceChange = (selectedOptions) => {
        setSelectedServices(selectedOptions);
    };

    // Handle social media link changes
    const handleSocialMediaChange = (index, field, value) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks[index][field] = value;
        setSocialMediaLinks(updatedLinks);
    };

    // Handle adding a new social media link
    const handleAddSocialMediaLink = () => {
        setSocialMediaLinks([...socialMediaLinks, {platform: '', url: ''}]);
    };

    // Handle removing a social media link
    const handleRemoveSocialMediaLink = (index) => {
        const updatedLinks = [...socialMediaLinks];
        updatedLinks.splice(index, 1);
        setSocialMediaLinks(updatedLinks);
    };

    // Handle team member input changes
    const handleTeamMemberChange = (index, field, value) => {
        const updatedTeamMembers = [...teamMembers];
        updatedTeamMembers[index][field] = value;
        setTeamMembers(updatedTeamMembers);
    };

    // Handle adding a new team member
    const handleAddTeamMember = () => {
        setTeamMembers([...teamMembers, {firstName: '', lastName: '', email: '', phone: '', role: ''}]);
    };

    // Handle removing a team member
    const handleRemoveTeamMember = (index) => {
        const updatedTeamMembers = [...teamMembers];
        updatedTeamMembers.splice(index, 1);
        setTeamMembers(updatedTeamMembers);
    };

    // Handle save changes
    const handleSaveChanges = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const docRef = doc(db, 'users', user.uid);
                const updatedData = {
                    ...formData, services: selectedServices.map(option => option.value), // Store services as an array of strings
                    socialMediaLinks, // Store social media links as array of objects
                    teamMembers // Store team members as array of objects
                };
                await updateDoc(docRef, updatedData); // Overwrite Firestore fields with form data
                setIsEditing(false); // Exit edit mode
            } catch (error) {
                console.error('Error updating document: ', error);
            }
        }
    };

    if (isLoading) {
        // Show loading indicator while fetching data
        return (<Container maxWidth="sm" sx={{marginTop: 8, textAlign: 'center'}}>
            <CircularProgress/>
        </Container>);
    }

    if (!userData) {
        // Show a message if no user data is available
        return (<Container maxWidth="sm" sx={{marginTop: 8, textAlign: 'center'}}>
            <Typography variant="h6">No user data available.</Typography>
        </Container>);
    }

    return (<Container maxWidth="md" sx={{marginTop: 8}}>
        <Paper elevation={3} sx={{padding: 6, width: '100%'}}>
            {/* Flexbox Layout */}
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Typography variant="h5" component="h1">
                    {userData.name || 'User Name'}
                </Typography>
                <Typography variant="body1">{userData.email}</Typography>

                {/* Editable Text Fields */}
                <TextField
                    label="Phone"
                    name="phone"
                    fullWidth
                    value={formData.contactPhone || ''}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                />

                <TextField
                    label="Address"
                    name="address"
                    fullWidth
                    value={formData.address || ''}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                />

                {userData.role === 'care center' && (<>
                    <TextField
                        label="Contact Name"
                        name="contactName"
                        fullWidth
                        value={formData.contactName || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                    />

                    <TextField
                        label="Website"
                        name="website"
                        fullWidth
                        value={formData.website || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                    />

                    <TextField
                        label="Brief Description"
                        name="briefDescription"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.briefDescription || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{maxLength: 500}}
                        helperText={`${formData.briefDescription ? formData.briefDescription.length : 0}/500 characters`}
                    />

                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        Is the care center accredited?
                    </Typography>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="accredited"
                                value="yes"
                                checked={formData.accredited === 'yes'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            Yes
                        </label>
                        <label style={{marginLeft: '1rem'}}>
                            <input
                                type="radio"
                                name="accredited"
                                value="no"
                                checked={formData.accredited === 'no'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            No
                        </label>
                    </div>
                    {formData.accredited === 'yes' && (<>
                        <TextField
                            label="Accreditation Year"
                            name="accreditationYear"
                            fullWidth
                            type="number"
                            value={formData.accreditationYear || ''}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            variant="outlined"
                            inputProps={{min: 1900, max: new Date().getFullYear()}}
                            sx={{mt: 2}}
                        />
                        <Typography variant="subtitle1" sx={{mt: 2}}>
                            The center is accredited for:
                        </Typography>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    name="accreditedForChildren"
                                    checked={formData.accreditedForChildren || false}
                                    onChange={(e) => setFormData({
                                        ...formData, accreditedForChildren: e.target.checked
                                    })}
                                    disabled={!isEditing}
                                />
                                Children
                            </label>
                            <label style={{marginLeft: '1rem'}}>
                                <input
                                    type="checkbox"
                                    name="accreditedForAdults"
                                    checked={formData.accreditedForAdults || false}
                                    onChange={(e) => setFormData({
                                        ...formData, accreditedForAdults: e.target.checked
                                    })}
                                    disabled={!isEditing}
                                />
                                Adults
                            </label>
                        </div>
                    </>)}

                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        Do you collaborate with other centers or hospitals?
                    </Typography>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="collaboration"
                                value="yes"
                                checked={formData.collaboration === 'yes'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            Yes
                        </label>
                        <label style={{marginLeft: '1rem'}}>
                            <input
                                type="radio"
                                name="collaboration"
                                value="no"
                                checked={formData.collaboration === 'no'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            No
                        </label>
                    </div>
                    {formData.collaboration === 'yes' && (<TextField
                        label="Collaboration Details"
                        name="collaborationDetails"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.collaborationDetails || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{maxLength: 300}}
                        helperText={`${formData.collaborationDetails ? formData.collaborationDetails.length : 0}/300 characters`}
                        sx={{mt: 1}}
                    />)}

                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        Is your care center part of any European Reference Networks?
                    </Typography>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="europeanReferenceNetwork"
                                value="yes"
                                checked={formData.europeanReferenceNetwork === 'yes'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            Yes
                        </label>
                        <label style={{marginLeft: '1rem'}}>
                            <input
                                type="radio"
                                name="europeanReferenceNetwork"
                                value="no"
                                checked={formData.europeanReferenceNetwork === 'no'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            No
                        </label>
                    </div>
                    {formData.europeanReferenceNetwork === 'yes' && (<TextField
                        label="ERN Details"
                        name="europeanReferenceNetworkDetails"
                        fullWidth
                        multiline
                        rows={2}
                        value={formData.europeanReferenceNetworkDetails || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{maxLength: 200}}
                        helperText={`${formData.europeanReferenceNetworkDetails ? formData.europeanReferenceNetworkDetails.length : 0}/200 characters`}
                        sx={{mt: 1}}
                    />)}

                    <Typography variant="subtitle1" sx={{mt: 2}}>
                        Are you connected with or supported by any patient organization?
                    </Typography>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="poConnection"
                                value="yes"
                                checked={formData.poConnection === 'yes'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            Yes
                        </label>
                        <label style={{marginLeft: '1rem'}}>
                            <input
                                type="radio"
                                name="poConnection"
                                value="no"
                                checked={formData.poConnection === 'no'}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            No
                        </label>
                    </div>
                    {formData.poConnection === 'yes' && (<TextField
                        label="Patient Organization Details"
                        name="poConnectionDetails"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.poConnectionDetails || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{maxLength: 300}}
                        helperText={`${formData.poConnectionDetails ? formData.poConnectionDetails.length : 0}/300 characters`}
                        sx={{mt: 1}}
                    />)}

                    <TextField
                        label="Number of Patients"
                        name="numberOfPatients"
                        fullWidth
                        type="number"
                        value={formData.numberOfPatients || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        inputProps={{min: 1}}
                        sx={{mt: 2}}
                    />
                </>)}

                {/* Conditionally Render Additional Fields for 'patient organization' */}
                {userData.role === 'patient organization' && (<>
                    <TextField
                        label="Contact Name"
                        name="contactName"
                        fullWidth
                        value={formData.contactName || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                    />

                    <TextField
                        label="Website"
                        name="website"
                        fullWidth
                        value={formData.website || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                    />

                    <TextField
                        label="Number of Patients"
                        name="numberOfPatients"
                        fullWidth
                        value={formData.numberOfPatients || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                    />

                    {/* Services Multi-Select */}
                    <Typography variant="body1">Services Provided</Typography>
                    <Select
                        isMulti
                        value={selectedServices}
                        onChange={handleServiceChange}
                        options={servicesOptions}
                        isDisabled={!isEditing}
                        styles={{
                            control: (provided) => ({
                                ...provided, width: '100%', minWidth: '300px', whiteSpace: 'nowrap', maxWidth: '100%' // Full width
                            }), menu: (provided) => ({
                                ...provided, width: '100%', maxWidth: '100%' // Make sure the dropdown menu is also full width
                            }),
                        }}
                        classNamePrefix="services-select"
                    />

                    {/* Social Media Section */}
                    <Typography variant="body1">Social Media Links</Typography>
                    {socialMediaLinks.map((link, index) => (<Box key={index} display="flex" gap={1} alignItems="center">
                        <Select
                            value={socialMediaOptions.find(option => option.value === link.platform)}
                            onChange={(selectedOption) => handleSocialMediaChange(index, 'platform', selectedOption.value)}
                            options={socialMediaOptions}
                            isDisabled={!isEditing}
                            styles={{
                                control: (provided) => ({
                                    ...provided, width: '200px',  // Adjusted width for readability
                                    minWidth: '180px', maxWidth: '100%',
                                }), menu: (provided) => ({
                                    ...provided, width: '100%', maxWidth: '100%',
                                }),
                            }}
                        />
                        <TextField
                            label="URL"
                            value={link.url}
                            onChange={(e) => handleSocialMediaChange(index, 'url', e.target.value)}
                            disabled={!isEditing}
                            fullWidth
                        />
                        {isEditing && (<IconButton onClick={() => handleRemoveSocialMediaLink(index)}>
                            <RemoveIcon/>
                        </IconButton>)}
                    </Box>))}
                    {isEditing && (<Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={handleAddSocialMediaLink}
                    >
                        Add Social Media
                    </Button>)}

                    {/* Team Members Section */}
                    <Typography variant="body1">Team Members</Typography>
                    {teamMembers.map((member, index) => (<Box key={index} display="flex" gap={1} alignItems="center">
                        <TextField
                            label="First Name"
                            value={member.firstName}
                            onChange={(e) => handleTeamMemberChange(index, 'firstName', e.target.value)}
                            disabled={!isEditing}
                        />
                        <TextField
                            label="Last Name"
                            value={member.lastName}
                            onChange={(e) => handleTeamMemberChange(index, 'lastName', e.target.value)}
                            disabled={!isEditing}
                        />
                        <TextField
                            label="Email"
                            value={member.email}
                            onChange={(e) => handleTeamMemberChange(index, 'email', e.target.value)}
                            disabled={!isEditing}
                        />
                        <TextField
                            label="Phone"
                            value={member.phone}
                            onChange={(e) => handleTeamMemberChange(index, 'phone', e.target.value)}
                            disabled={!isEditing}
                        />
                        <Select
                            value={teamMemberRoles.find(role => role.value === member.role)}
                            onChange={(selectedOption) => handleTeamMemberChange(index, 'role', selectedOption.value)}
                            options={teamMemberRoles}
                            isDisabled={!isEditing}
                            styles={{
                                control: (provided) => ({
                                    ...provided, width: '150px',  // Adjusted for readability
                                    minWidth: '150px', maxWidth: '100%',
                                }), menu: (provided) => ({
                                    ...provided, width: '100%', maxWidth: '100%',
                                }),
                            }}
                        />
                        {isEditing && (<IconButton onClick={() => handleRemoveTeamMember(index)}>
                            <RemoveIcon/>
                        </IconButton>)}
                    </Box>))}

                    {isEditing && (<Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={handleAddTeamMember}
                    >
                        Add Team Member
                    </Button>)}
                </>)}

                {/* Save Changes Button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => (isEditing ? handleSaveChanges() : setIsEditing(true))}
                >
                    {isEditing ? 'Save Changes' : 'Edit Profile'}
                </Button>
            </Box>
        </Paper>
    </Container>);
};

export default ProfilePage;
